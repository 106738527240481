<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue'
import TheIcon from '../TheIcon.vue'

const bannerConfigHostel2 = {
    mainText: 'Viajero Tayrona near Santa Marta is dropping prices by up to 20%.',
    mainTextColor: '#fff',
    ctaText: 'Unlock Deals Now',
    buttonColor: '#ff7f50',
    buttonColorHover: 'orange',
    buttonColorText: '#000',
    backgroundGradient: 'linear-gradient(45deg,#333,#6a0dad)',
    affiliateLink: 'https://prf.hn/l/ngRDAao/',
    image: '/images/banner/viajerobanner.jpg'
}
const bannerConfigHostel3 = {
    mainText: ' Sir Toby\'s Midtown in Prague is dropping prices by up to 20%.',
    mainTextColor: '#fff',
    ctaText: 'Unlock Deals Now',
    buttonColor: '#ff7f50',
    buttonColorHover: 'orange',
    buttonColorText: '#000',
    backgroundGradient: 'linear-gradient(45deg,#333,#6a0dad)',
    affiliateLink: 'https://prf.hn/l/p3QnN0v/',
    image: 'https://www.hostelz.com/images/banner/sirtobysbanner.jpg'
}
const bannerConfigHostel1 = {
    mainText: 'Rivoli Cinema Hostel in Porto is dropping prices by up to 15%.',
    mainTextColor: '#ffffff',
    ctaText: 'Unlock Deal Now',
    buttonColor: '#ff7f50',
    buttonColorHover: 'orange',
    buttonColorText: '#000',
    backgroundGradient: 'linear-gradient(45deg,#333,#6a0dad)',
    affiliateLink: 'https://prf.hn/l/JpyL1PN/',
    image: 'https://www.hostelz.com/images/banner/rivolibanner.jpg'
}

const bannerConfig = {
    mainText: 'eSim Deal Alert: Use "SECURITY15" and snag 15% off your Saily eSIM. Travel smart, save big!',
    mainTextColor: '#000',
    ctaText: 'Unlock Deal Now',
    buttonColor: '#ffe700',
    buttonColorHover: 'orange',
    buttonColorText: '#000',
    backgroundGradient: 'linear-gradient(75deg, #cee1f3, #cee1f3)',
    affiliateLink: 'https://www.hostelz.com/saily'
}

const bannerConfig2 = {
    mainText: 'Travel Deal Alert: HeyMondo just dropped a 5% discount on their travel insurance',
    mainTextColor: '#fff',
    ctaText: 'Unlock Deal Now',
    buttonColor: '#ff7f50',
    buttonColorHover: 'orange',
    buttonColorText: '#000',
    backgroundGradient: 'linear-gradient(45deg, #004d40, #007a52)',
    affiliateLink: 'https://heymondo.com/?utm_medium=Afiliado&utm_source=HOSTELZ&utm_campaign=PRINCIPAL&cod_descuento=HOSTELZ&ag_campaign=BANNER&agencia=8rNgRXTeIfa5Pk1fxRdzHCzJXv3Fj1QtUIrA9WjP'
}

const bannerConfig3 = {
    mainText: 'Black Friday Alert: Hostelworld is dropping prices by up to 60%.',
    mainTextColor: '#fff',
    ctaText: 'Unlock Deals Now',
    buttonColor: '#ff7f50',
    buttonColorHover: 'orange',
    buttonColorText: '#000',
    backgroundGradient: 'linear-gradient(45deg,#333,#6a0dad)',
    affiliateLink: ''
}

const banners = [bannerConfigHostel1, bannerConfigHostel2, bannerConfigHostel3]
const currentBannerIndex = ref(0)

// Cycle through banners every 24 hours
const updateCurrentBanner = (): void => {
    const currentTime = new Date()
    const hours = currentTime.getUTCHours() 
    currentBannerIndex.value = Math.floor(hours / 24) % banners.length 
}
updateCurrentBanner()

// Define the duration in minutes
const BANNER_HIDE_DURATION_MIN = 30
const BANNER_HIDE_DURATION = BANNER_HIDE_DURATION_MIN * 60 * 1000

const countdownText = ref('')
let countdownInterval: ReturnType<typeof setInterval> | null = null

const hideTemporaryBanner = ref(false)

// Replace the static countDownDate with a function to calculate the next target date
const getNextCountDownDate = (): number => {
    const now = new Date().getTime()
    const elevenDays = 11 * 24 * 60 * 60 * 1000 // 11 days in milliseconds
    const cycles = Math.floor(now / elevenDays)
    return (cycles + 1) * elevenDays
}

let countDownDate = getNextCountDownDate()

const updateCountdown = (): void => {
    const now = new Date().getTime()
    const distance = countDownDate - now

    if (distance < 0) {
        // Reset countdown to next 11-day period
        countDownDate = getNextCountDownDate()
        return
    }

    const days = Math.floor(distance / (1000 * 60 * 60 * 24))
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((distance % (1000 * 60)) / 1000)

    countdownText.value = `Only ${ days }d ${ hours }h ${ minutes }m ${ seconds }s left!`
}

onMounted(() => {
    const hideUntil = localStorage.getItem('promotionalBanner')

    if (hideUntil && Date.now() < parseInt(hideUntil, 10)) {
        hideTemporaryBanner.value = false
    } else {
        hideTemporaryBanner.value = true
        updateCountdown()
        countdownInterval = setInterval(updateCountdown, 1000)
    }
})

onUnmounted(() => {
    if (countdownInterval) {
        clearInterval(countdownInterval)
    }
})

const closeTemporaryBanner = (): void => {
    // Open the affiliate link in a new tab
    window.open(banners[currentBannerIndex.value].affiliateLink, '_blank')
    
    // Close the banner and set expiry date
    hideTemporaryBanner.value = false
    localStorage.setItem('promotionalBanner', (Date.now() + BANNER_HIDE_DURATION).toString())

    if (countdownInterval) {
        clearInterval(countdownInterval)
    }
}
</script>
<template>
    <div v-if="hideTemporaryBanner" class="container-fluid text-white py-3 temporary-banner position-relative">
        <div class="row align-items-center justify-content-center text-center">
            <div class="col-12 col-md-7">
                <a
                    :href="banners[currentBannerIndex].affiliateLink"
                    target="_blank"
                    class=""
                    rel="nofollow"
                    :title="banners[currentBannerIndex].mainText" 
                ><img 
                    :src="banners[currentBannerIndex].image" 
                    :alt="banners[currentBannerIndex].mainText" 
                    class="img-fluid mb-3 mr-2"
                    style="width: 140px; float:left; border-radius: 5px; border: 3px solid #fff;" 
                ></a>
                <h5 class="mb-0 temporary-banner-title mx-4 mx-md-0">
                    <span class="font-weight-normal">Global Hostel Deal of the Day:</span><br>
                    {{ banners[currentBannerIndex].mainText }}
                    <span class="font-weight-normal text-xs d-block mt-2">{{ countdownText }}</span>
                </h5>
            </div>
            <div class="col-12 col-md-3 mt-3 mt-md-0">
                <a
                    :href="banners[currentBannerIndex].affiliateLink"
                    target="_blank"
                    class="btn btn-lg px-3 px-xl-4 pulse-animation temporary-banner-link"
                    rel="nofollow"
                    :title="banners[currentBannerIndex].mainText" 
                >
                    {{ banners[currentBannerIndex].ctaText }}
                </a>
            </div>
        </div>
        <button
            type="button"
            class="temporary-banner-close close"
            aria-label="Close"
            @click="closeTemporaryBanner"
        >
            <TheIcon    
                icon-id="close-icon-2"
                width="24"
                height="24"
                :stroke="banners[currentBannerIndex].mainTextColor"
            />
        </button>
    </div>
</template>

<style scoped lang="scss">
.temporary-banner {
    background: v-bind('banners[currentBannerIndex].backgroundGradient');
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    z-index: 1050;

    &-title {
        font-family: var(--font-family-poppins-sans-serif);
        color: v-bind('banners[currentBannerIndex].mainTextColor');
        font-size: 18px;
        font-weight: 600;
    }

    &-link {
        background-color: v-bind('banners[currentBannerIndex].buttonColor');
        color: v-bind('banners[currentBannerIndex].buttonColorText');
        font-weight: bold;
        border: 2px solid #fff;
        font-size: 1rem;
        @media (min-width: 1200px) {
            font-size: 1.1rem;
        }
    }

    &-close {
        position: absolute;
        top: 10px;
        right: 10px;
        transition: stroke 0.3s, opacity 0.3s;

        &:focus {
            outline: none;
        }
    }
}

.pulse-animation {
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    animation: pulse 2s infinite;
}
.pulse-animation:hover {
    background-color: v-bind('banners[currentBannerIndex].buttonColorHover');
    transform: scale(1.05);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.4);
}
@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}
</style>