<script setup lang="ts">
import { computed, onMounted, onUnmounted } from 'vue'
import {
    cookies,
    cookiesHide,
    cookiesShow,
    toggleCookiesAnimationMoveToTop,
    cookiesAnimationMoveToTop
} from '@/Composables/cookiesInfo'
import { CONSENT_DENIED, CONSENT_GRANTED, gtag } from '@/Composables/googleAnalyticsEvents'
import { useMobile } from '@/Composables/mobile'
import { bottomBarRef } from '@/Composables/bottomBarState'

const { isMobile } = useMobile()

let lastScrollY: number

onMounted(() => {
    if (!localStorage.getItem('consentMode')) {
        cookiesShow()
    }

    window.addEventListener('scroll', handleScroll)
})

const setConsent = (accept: string): void => {
    accept === 'true'
        ? gtag('consent', 'update', CONSENT_GRANTED)
        : gtag('consent', 'update', CONSENT_DENIED)

    localStorage.setItem('consentMode', accept)

    cookiesHide()
}

const cookiesAnimation = computed(() => {
    if (cookiesAnimationMoveToTop.value) {
        return {
            transform: 'translateY(0)'
        }
    } else {
        const bannerHeight = bottomBarRef.value ? bottomBarRef.value.offsetHeight : 0
        return {
            transform: `translateY(-${ bannerHeight - 2 }px)`
        }
    }
})

const handleScroll = (): void => {
    const currentScrollY = window.scrollY

    if (isMobile.value && bottomBarRef.value) {
        if (currentScrollY > lastScrollY && currentScrollY > 160) {
            toggleCookiesAnimationMoveToTop(true)
        } else {
            toggleCookiesAnimationMoveToTop(false)
        }

        lastScrollY = currentScrollY
    }
}

onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll)
})

</script>

<template>
    <div
        v-if="cookies"
        id="cookie-consent-banner"
        class="position-sticky bottom-0 w-100 border-top cookies-agreement bg-gray-100 shadow-1"
        :style="cookiesAnimation"
    >
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="d-flex flex-column flex-sm-row">
                        <div class="p-3">
                            <p class="font-weight-600">
                                Sharing Cookies with Hostelz.com 🍪
                            </p>

                            <p class="m-0 cookies-agreement-text">
                                We use cookies to make your online journey better. They help us to improve Hostelz for
                                you, ensuring your digital journey is as epic as your real one.
                            </p>
                        </div>
                        <div class="p-3 pb-4 d-flex flex-column justify-content-around order-1 order-sm-0">
                            <button
                                id="btn-accept-all"
                                type="button"
                                class="btn btn-success text-nowrap"
                                @click="setConsent('true')"
                            >
                                Accept All
                            </button>
                            <button
                                id="btn-reject-all"
                                type="button"
                                class="btn btn-sm btn-default text-nowrap"
                                @click="setConsent('false')"
                            >
                                Reject All
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.cookies-agreement {
    transition: transform .3s linear;
    z-index: 1052;

    &-text {
        font-size: 0.7rem;

        @media (min-width: 576px) {
            font-size: 0.875rem;
        }
    }
}
</style>
