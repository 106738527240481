<script setup lang="ts">

import 'styles/theme.scss'
import 'styles/custom.scss'
import 'styles/_colors.scss'

import ScrollButton from '@/Components/ScrollButton.vue'
import TheFooter from '@/Components/the-footer/TheFooter.vue'
import TheHead from '@/Components/TheHead.vue'
import BottomBar from '@/Components/BottomBar.vue'
import CookiesAgreement from '@/Components/CookiesAgreement.vue'
import TemporaryBanner from '@/Components/temporary-banner/TemporaryBanner.vue'
import { currencyForServer, currentCurrency } from '@/Composables/use-currency'
import { usePage } from '@inertiajs/vue3'
import { onMounted } from 'vue'

defineProps<{
    isHideFooter?: boolean
    noIndex?: boolean
    isHideSignup?: boolean
}>()

const page = usePage()

onMounted(() => {
    currentCurrency.value = page.props.activeCurrency.title
    currencyForServer.value = page.props.activeCurrency.code
})

</script>

<template>
    <TheHead>
        <template v-if="$slots.head">
            <slot name="head" />
        </template>

        <meta v-if="noIndex" name="robots" content="noindex">
    </TheHead>

    <TemporaryBanner />

    <template v-if="$slots.header">
        <slot name="header" />
    </template>

    <div id="app-content">
        <main id="app-main">
            <slot />
        </main>

        <TheFooter v-if="!isHideFooter" :is-hide-signup="isHideSignup" />
    </div>

    <ScrollButton />
    <BottomBar />
    <CookiesAgreement />
</template>

<style lang="scss">
#app {
    &-content {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    }

    &-main {
        flex: 1;
    }
}
</style>