<script setup lang="ts">

import { ref, computed, onMounted, Ref } from 'vue'
import TheIcon from '@/Components/TheIcon.vue'
import { Currency } from '@/Interfaces/Currency'
import {
    setCurrentCurrency,
    setCurrencyForServer,
    currencyUpdate,
    currencyForServer
} from '../Composables/use-currency'
import { usePage } from '@inertiajs/vue3'

const page = usePage()

const currencyArr = ref<Currency[]>(page.props.currencies as Currency[])

const emit = defineEmits(['close-modal'])

const container: Ref<HTMLElement | null> = ref(null)

onMounted(() => {
    const selectedElement: HTMLElement | null = container.value?.querySelector('.selected') || null
    if (selectedElement) {
        selectedElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
    }
})

const chooseCurrency = (dataForClient: string, dataForServer: string): void => {
    setCurrentCurrency(dataForClient)

    setCurrencyForServer(dataForServer)

    currencyUpdate(dataForClient, dataForServer)

    emit('close-modal')
}

const searchQuery = ref('')

const resetSearchQuery = (): void => {
    searchQuery.value = ''
}

const filteredCurrencies = computed(() => {
    return currencyArr.value.filter((currency) =>
        currency.title.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
        currency.code.toLowerCase().includes(searchQuery.value.toLowerCase())
    )
})

</script>

<template>
    <div class="currency-exchange">
        <div class="currency-exchange-header">
            <h5 class="currency-exchange-title">
                Currency Exchange
            </h5>
            <div class="currency-exchange-search">
                <input
                    v-model="searchQuery"
                    name="location"
                    placeholder="Search currency"
                    type="text"
                    class="form-control bg-light rounded-xl border-0 "
                >
                <div class="currency-exchange-clear-btn" @click="resetSearchQuery">
                    <TheIcon icon-id="clear" width="22" height="22" />
                </div>
            </div>
        </div>
        <div class="currency-exchange-body">
            <div class="currency-exchange-inner">
                <div ref="container" class="container-fluid">
                    <div class="row">
                        <div
                            v-for="item in filteredCurrencies"
                            :key="item.code"
                            class="col-md-6 col-lg-6 currency-exchange-item "
                            :class="{ selected: currencyForServer === item.code}"
                            @click="chooseCurrency(item.title, item.code)"
                        >
                            <span>{{ item.title }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<style lang="scss" scoped>
.currency-exchange {
    height: 70vh;
    overflow: hidden;
    width: 800px;

    @media (max-width: 768px) {
        width: 500px;
    }

    @media (max-width: 576px) {
        width: 100%;
    }

    &-header {
        align-items: flex-start;
        border-bottom: 1px solid rgba(236, 239, 244, .5);
        border-top-left-radius: .6rem;
        border-top-right-radius: .6rem;
        display: flex;
        padding: 18px 18px 20px;
        gap: 100px;


        @media (max-width: 768px) {
            gap: 16px;
        }

    }

    &-title {
        padding-top: 8px;
        margin-bottom: 0;

        font-size: 18px;
        font-weight: 600;
        line-height: 1.4;

    }

    &-search {
        position: relative;

        @media (max-width: 576px) {
            margin-right: 30px;
        }

        input {
            background-image: url("data:image/svg+xml, %3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.75 10.0833C3.75 6.58553 6.58553 3.75 10.0833 3.75C13.5811 3.75 16.4167 6.58553 16.4167 10.0833C16.4167 11.7875 15.7436 13.3345 14.6488 14.4729C14.6164 14.4981 14.5852 14.5256 14.5554 14.5554C14.5256 14.5852 14.4981 14.6164 14.4728 14.6488C13.3344 15.7436 11.7875 16.4167 10.0833 16.4167C6.58553 16.4167 3.75 13.5811 3.75 10.0833ZM15.2264 16.6407C13.81 17.7532 12.0241 18.4167 10.0833 18.4167C5.48096 18.4167 1.75 14.6857 1.75 10.0833C1.75 5.48096 5.48096 1.75 10.0833 1.75C14.6857 1.75 18.4167 5.48096 18.4167 10.0833C18.4167 12.0242 17.7532 13.81 16.6407 15.2265L19.9571 18.5429C20.3476 18.9334 20.3476 19.5666 19.9571 19.9571C19.5666 20.3477 18.9334 20.3477 18.5429 19.9571L15.2264 16.6407Z' fill='%23787D8D'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: 7% 50%;
            padding-left: 54px;
            padding-right: 54px;

            @media (max-width: 768px) {
                padding-right: 24px;
            }

            @media (max-width: 576px) {
                padding-right: 54px;
            }
        }
    }

    &-body {
        height: calc(100% - 90px);
        padding: 16px;
        overflow-y: auto;
    }

    &-item {
        margin-top: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        text-align: center;
        border-radius: 5px;

        &:hover {
            background-color: #dae0e5;
            cursor: pointer;
        }

        span {
            font-size: 16px;
            font-weight: 400;
            height: auto;
            line-height: 1.4;
        }
    }

    &-clear-btn {
        position: absolute;
        top: 50%;
        right: 8%;
        transform: translate(0%, -50%);
        cursor: pointer;
    }

    .selected {
        background-color: #4a5268;
        color: #fafbfe;
    }
}


</style>
  